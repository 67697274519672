<template>
  <v-dialog v-model="dialog" width="600" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Editar</span>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-divider />
        <v-container>
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                label="Grupo de donación"
                required
                outlined
                hide-details
                v-model="donationGroup.group"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                label="Meta de donación anual"
                required
                outlined
                hide-details
                v-model="donationGroup.goal"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="8">
              <v-select
                label="Programa Predefinido"
                :items="donationsType"
                item-text="donation_type"
                item-value="id_donation_type"
                required
                outlined
                hide-details
                v-model="donationGroup.id_donation_type"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="12">
              <v-textarea
                clearable
                clear-icon="mdi-close-circle"
                outlined
                auto-grow
                label="Descripción"
                rows="4"
                row-height="30"
                hide-details
                v-model="donationGroup.description"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="changeModal()">
          Cancelar
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="update()"
          :loading="isLoading"
        >
          Modificar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'UpdateDonationGroup',
  data() {
    return {
      isLoading: false,
      dialog: false,
    };
  },
  props: {
    donationGroup: Object,
    donationsType: Array,
    clean: { type: Function },
  },
  mounted() {},
  methods: {
    ...mapActions({
      openSnackbar: 'Snackbar/openSnackbar',
    }),
    changeModal: function() {
      this.dialog = !this.dialog;
    },
    update: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.api + 'group/' + this.donationGroup.id_group,
          this.donationGroup
        )
        .then(({ data }) => {
          this.openSnackbar({
            type: data.response ? 'success' : 'warning',
            message: data.message,
          });
          if (data.response) {
            this.changeModal();
            this.clean();
          }
        })
        .catch((err) => {
          this.openSnackbar({
            type: 'danger',
            message: 'Error al realizar la petición',
          });
        })
        .then(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      api: 'getApi',
    }),
  },
};
</script>

<style></style>
